import './App.css';
import Testimonials from './assets/Testimonials/Testimonials';
import Footer from './component/Footer/Footer';
import Hero from './component/Hero section/Hero';
import Program from './component/Hero section/Programs/Program';
import Join from './component/Join/Join';
import Plans from './component/Plans/Plans';
import Reasons from './component/Reasons/Reasons';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Program/>
          <Reasons/>
          <Plans/>
          <Testimonials/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
